import React from 'react'
import Navbar from '../components/navbar/Navbar'
import ContactUs from '../pages/contactUs/ContactUs'
import Footer from '../components/footer/Footer.jsx'

const Contact_Us = () => {
    return (
        <div>
            <Navbar />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default Contact_Us