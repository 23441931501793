import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import About from '../pages/aboutUs/AboutUs'

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <About />
      <Footer />
    </div>
  )
}

export default AboutUs