import React from "react";
import "./Gallery.css";

import pic1 from "../../assets/gallery/1.jpg"
import pic2 from "../../assets/gallery/2.jpg"
import pic3 from "../../assets/gallery/3.jpg"
import pic4 from "../../assets/gallery/4.jpg"
import pic5 from "../../assets/gallery/5.jpg"
import pic6 from "../../assets/gallery/6.jpg"
import pic7 from "../../assets/gallery/7.jpg"
import pic8 from "../../assets/gallery/8.jpg"
import pic9 from "../../assets/gallery/9.jpg"
import pic10 from "../../assets/gallery/10.jpg"

import Slider from "react-slick";
// import { baseUrl } from "./config";

const Gallery = () => {

    

  let settings = {
    dots: true,
    infinite: true,
    speed: 1800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };
  return (
    <>
      <section className="gallery">
        <h2>Our Gallery</h2>
        <div className="gallery_slider">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="imgContainer">
                <img src={pic1} alt="hotel-image1" />
              </div>
              <div className="imgContainer">
                <img src={pic2} alt="hotel-image2" />
              </div>
              <div className="imgContainer">
                <img src={pic3} alt="hotel-image3" />
              </div>
              <div className="imgContainer">
                <img src={pic4} alt="hotel-image4" />
              </div>
              <div className="imgContainer">
                <img src={pic5} alt="hotel-image5" />
              </div>
              <div className="imgContainer">
                <img src={pic6} alt="hotel-image6" />
              </div>
              <div className="imgContainer">
                <img src={pic7} alt="hotel-image7" />
              </div>
              <div className="imgContainer">
                <img src={pic8} alt="hotel-image8" />
              </div>
              <div className="imgContainer">
                <img src={pic9} alt="hotel-image9" />
              </div>
              <div className="imgContainer">
                <img src={pic10} alt="hotel-image10" />
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
